import styles from './Contact.module.css';
import avl from '../Assets/interior.jpg';

function Contact() {
    const handleButtonClick = (action: string) => {
        if (localStorage.getItem('cookiePreferenceLaRumba') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Contact',
            'event_action': action,
        });
    }

    return (
        <section className={styles.contact}>
            <div className={styles.left}>
                <p className={styles.title}>Find Us</p>
                <div className={styles.section} style={{marginBottom: 48}}>
                    <p className={styles.subtitle}>WHERE</p>
                    <p className={styles.text} style={{marginBottom: 8}}>105 River Hills Rd Suite C, Asheville, NC 28805</p>
                    <p className={styles.text} style={{marginBottom: 8}}>(828) 424-7137</p>
                    <p className={styles.text}>(828) 505-2128</p>
                    <div className={styles.buttonsContainer}>
                        <a href="tel:828-424-7137" aria-label="Call Now" className={styles.call} onClick={() => handleButtonClick("Call Now")}>CALL NOW</a>
                        <a 
                        href="https://www.google.com/maps/dir/?api=1&destination=105+River+Hills+Rd+suite+C,+Asheville,+NC+28805"
                        target="_blank" 
                        rel="noopener noreferrer"
                        aria-label="Get directions to our location: 105 River Hills Rd suite C, Asheville, NC 28805"
                        className={styles.directions}
                        onClick={() => handleButtonClick("Get Directions")}
                        >GET DIRECTIONS</a>
                    </div>
                </div>
                <div className={styles.section}>
                    <p className={styles.subtitle}>WHEN</p>
                    <p className={styles.text} style={{marginBottom: 12}}>Monday - Saturday | 11am - 9:30pm</p>
                    <p className={styles.text}>Sunday | 11am - 8pm</p>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.imageContainer}>
                    <img loading="lazy" src={avl} className={styles.image} alt="La Rumba's Beautiful Latin American Interior Vibe" />
                </div>
            </div>
        </section>
    )
}

export default Contact;