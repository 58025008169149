import styles from './Alert.module.css'

function Alert() {
    return (
        <section className={styles.alert}>
            <p className={styles.mainAlert}>!! WE ARE OPEN !! (Short Detour Required)</p>
            <p className={styles.subAlert}>{'Please enter from Home Depot Exit 8 instead, then map will then re-route to La Rumba. Exit 8 -> Fairview Rd -> Turn onto Cedar St -> La Rumba.'}</p>
            <p className={styles.subSubAlert}>{'This is unfortunately due to Hurricane Helene damage to nearby roads. Thank you for understanding.'}</p>
        </section>
    )
}

export default Alert;