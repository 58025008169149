import { useState } from 'react';
import styles from './InitialCookies.module.css';
import Cookies from './Cookies';

function InitialCookies(props: {close: (option: string) => void;}) {
    const handleButtonClick = () => {
        if (localStorage.getItem('cookiePreferenceLaRumba') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Initial Cookies',
            'event_action': "Accept",
        });
    }

    return (
        <div className={styles.initialCookies}>
            <p className={styles.initialCookiesTitle}>Your Privacy, Our Priority.</p>
            <div className={styles.initialCookiesLower}>
                <p className={styles.initialCookiesText}>By using this site, you agree to our use of cookies.</p>
                <div className={styles.buttonsContainer}>
                    <button className={styles.acceptButton} onClick={() => {props.close("On"); handleButtonClick();}}>ACCEPT</button>
                </div>
            </div>
        </div>
    )
}

export default InitialCookies;