import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import styles from './Gallery.module.css';
import { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';

function Gallery() {
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState<any>([]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await fetch(`https://api.larumbaavl.com/api/galleries?populate=*`);
            const data = await res.json();
            setIsLoading(false);
            setImages(data.data[0].attributes.Media.data);
        } catch (error) {
            console.log("Error fetching gallery");
        }
    }

    const override: any = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate3d(-50%, -50%, 0) rotate(90deg)"
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <section className={styles.gallery}>
            <Helmet>
                <title>La Rumba | Gallery</title>
                <meta name="description" content="Step into the vibrant world of La Rumba through our gallery. Discover the heart and soul of Latin American cuisine in Asheville as you feast your eyes on our mouthwatering dishes, lively ambiance, and memorable moments. Experience the rhythm and flavors with us." />
                <link rel="canonical" href="https://larumbaavl.com/gallery" />
            </Helmet>
            <Header />
            <div className={styles.body}>
                <h1 className={styles.title}>La Rumba's Gallery</h1>
                <div className={styles.line} />
                {isLoading && <PulseLoader color="white" cssOverride={override}/>}
                {images.length > 0 && <div className={styles.images}>
                    {images.map((el: any, idx: any) => (
                        <div className={styles.imageContainer} key={idx}>
                            <img src={el.attributes.url} className={styles.image} alt="Gallery Image"/>
                        </div>
                    ))}
                </div>}
            </div>
            <Footer />
        </section>
    )
}

export default Gallery;