import styles from './PrivacyPolicy.module.css';

function PrivacyPolicy(props: {close: () => void;}) {
    const handleButtonClick = () => {
        if (localStorage.getItem('cookiePreferenceLaRumba') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Privacy Policy',
            'event_action': "Ok",
        });
    }

    return (
        <div className={styles.privacyPolicyBackdrop} onClick={() => props.close()}>
            <div className={styles.privacyPolicy} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}>
                    <p className={styles.privacyPolicyTitle}>Privacy Policy for La Rumba</p>
                    <p className={styles.lastUpdated}>Last Updated: November 2023</p>
                </div>
                <div className={styles.body}>
                    <div className={styles.section}>
                        <p className={styles.sectionTitle}>Introduction</p>
                        <p className={styles.sectionText}>Welcome to La Rumba! This Privacy Policy explains how we collect, use, and safeguard your personal information when you interact with us, specifically through the contact form on our website.</p>
                    </div>
                 <div className={styles.section}>
                        <p className={styles.sectionTitle}>Data We Collect</p>
                        <p className={styles.sectionText}>We collect anonymous analytical data like user clicks, page views, etc. through cookies.
                        </p>
                    </div>
                    <div className={styles.section}>
                        <p className={styles.sectionTitle}>How We Use Your Data</p>
                        <p className={styles.sectionText}>We use these analytics to improve the user experience for you as the end user.</p>
                    </div>
                    <div className={styles.section}>
                        <p className={styles.sectionTitle}>Data Storage and Security</p>
                        <p className={styles.sectionText}>The anonymous analytics information is stored at Google's servers, which is protected through their security protocols. However, while we take incredible measures to protect your information, no method of electronic storage is 100% secure.</p>
                    </div>
                    <div className={styles.section}>
                        <p className={styles.sectionTitle}>Data Sharing</p>
                        <p className={styles.sectionText}>We do not share your personal information with any third parties for their promotional purposes. However, we may disclose information where required by law or in the interest of protecting or exercising our or others’ legal rights, e.g., without limitation, in connection with requests from law enforcement.</p>
                    </div>
                    <div className={styles.section}>
                        <p className={styles.sectionTitle}>Changes to This Policy</p>
                        <p className={styles.sectionText}>We may update this Privacy Policy from time to time. Any changes will be effective immediately upon the posting of the revised Privacy Policy. We will not, however, use your previously collected personal information in a manner materially different than stated at the time it was collected without your consent.</p>
                    </div>
                    <div className={styles.section}>
                        <p className={styles.sectionTitle}>Contact Information</p>
                        <p className={styles.sectionText}>For questions related to this Privacy Policy, please contact Archetype at:
                        <br/><br/>Email: archetypeavl@outlook.com</p>
                    </div>
                </div>
                <div className={styles.footer}>
                    <button className={styles.footerButton} onClick={() => {
                        handleButtonClick()
                        props.close()
                    }}>OK</button>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;