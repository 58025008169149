import { useEffect, useState } from 'react';
import styles from './Reviews.module.css';

function Reviews() {
    const [mainIdx, setMainIdx] = useState(0);

    const Star = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#fcba03" height="18px"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
        )
    }

    const reviews = [
        {
            name: "Tracy Danet | via Google Reviews",
            text: "This place is spot on perfect!<br/><br/>It’s clean and beautiful inside the space, the staff is friendly, and the food was so delicious.<br/>We love seeing local food on the menu, and trying authentic latin food. Everything was cooked, prepared, and plated perfectly.<br/>I asked for vegetarian options, and the staff gave great recommendations and detailed information about items on the menu.<br/><br/>We will never forget our experience here. I highly recommend this place to anyone!!"
        },
        {
            name: "Pamela Stikeleather | via Google Reviews",
            text: "Stepping through the revolving door, such a fun touch. Warm colors floor to ceiling, cushioned booths, Large picture windows, and lots of space to seat any party number. Having Street Corn as a welcome to our restaurant for all that dine, was not only a surprise, I loved it. The Tostones Rellenos appetizer was yummy! Along with everything else. Delicious and quick. We will be back."
        },
        {
            name: "Chase Schwalbach | via Google Reviews",
            text: "Love the new vegan menu! Excellent atmosphere and food. The vegan burrito with the chorizo is really great and the owner Ray came around and was super friendly!<br/><br/>Update - even more vegan friendly! They updated their street corn that they give out when you get there to also have a vegan option!"
        },
        {
            name: "Chris White | via Google Reviews",
            text: "Really can’t say enough good things about this place. The staff are so friendly and warm, the atmosphere is perfect for any occasion, and the food...<br/><br/>Let me say that if you’re looking for traditional Tex-Mex then go elsewhere. This isn’t you’re everyday sloppy cheese, ground meat, and tortilla place with 30 different combinations of the same dish.<br/><br/>This is something truly unique and special."
        },
        {
            name: "Christina Mitchell | via Google Reviews",
            text: "I am SO happy to have found La Rumba, which will be my new go to spot! It answers my constant query of where to find high quality affordable food and drinks! Hickory Nut Gap Chorizo… say what?! Yep, local meat! Sauces and dressings made with clean real ingredients and not sugar and canola oil.. wow! Finally a place that’s doing it right and not over charging! Thank you! Almost forgot to mention the clean, classy environment and super friendly staff!"
        }
    ];
    
    return (
        <section className={styles.reviews}>
            <div className={styles.review}>
                <p className={styles.text} dangerouslySetInnerHTML={{ __html: reviews[mainIdx].text }}></p>
                <div className={styles.nameContainer}>
                    <div className={styles.stars}>
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                    </div>
                    <p className={styles.name}>{reviews[mainIdx].name}</p>
                </div>
            </div>
            <div className={styles.mobileNav}>
                <div className={styles.buttonLeftMobile} onClick={() => setMainIdx(prev => (prev - 1 + reviews.length) % reviews.length)}><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 320 512" fill="black"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>
                <div className={styles.buttonRightMobile} onClick={() => setMainIdx(prev => (prev + 1) % reviews.length)}><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 320 512" fill="black"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>
            </div>
            <div className={styles.buttonLeft} onClick={() => setMainIdx(prev => (prev - 1 + reviews.length) % reviews.length)}><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 320 512" fill="black"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>
            <div className={styles.buttonRight} onClick={() => setMainIdx(prev => (prev + 1) % reviews.length)}><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 320 512" fill="black"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>
        </section>
    )
}

export default Reviews;