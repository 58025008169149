import { useEffect, useState } from 'react';
import styles from './Header.module.css';
import { Link } from 'react-router-dom';

function Header() {
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const handleButtonClick = (action: string) => {
        if (localStorage.getItem('cookiePreferenceLaRumba') == "Off") return;

        gtag('event', 'click', {
            'event_category': 'Header',
            'event_action': action,
        });
    }

    const handleOrderClick = () => {
        if (localStorage.getItem('cookiePreferenceLaRumba') == "Off") return;
        
        gtag('event', 'purchase', {
            'event_category': 'Header',
            'event_action': 'Order Online',
            'value': 44.99,
            'currency': 'USD'
        });
    }

    useEffect(() => {
        let ticking = false;

        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const percentage = Math.min(window.scrollY / (document.body.clientHeight - window.innerHeight) * 10, 1);
                    setScrollPercentage(percentage);
                    ticking = false;
                });
                ticking = true;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const backgroundColor = `rgba(0, 0, 0, ${scrollPercentage})`;

    return (
        <header className={styles.header} style={{backgroundColor: showMobileMenu ? "black" : backgroundColor}}>
            <div className={styles.left}>
                <Link className={styles.navText} to={"/"} onClick={() => handleButtonClick("Home")}>HOME</Link>
                <Link className={styles.navText} to={"/menus"} onClick={() => handleButtonClick("Menus")}>MENUS</Link>
                <Link className={styles.navText} to={"/gallery"} onClick={() => handleButtonClick("Gallery")}>GALLERY</Link>
            </div>
            <div className={styles.center}>
                <p className={styles.logoText}>LA RUMBA</p>
            </div>
            <div className={styles.right}>
                <a href="https://order.chownow.com/order/38254/locations" target="_blank" className={styles.navButton} onClick={() => handleOrderClick()}>ORDER ONLINE</a>
            </div>
            <div className={styles.mobileNav}>
                <Link className={styles.mobileLogo} to={"/"}>LA RUMBA</Link>
                <svg xmlns="http://www.w3.org/2000/svg" height="28px" fill="white" viewBox="0 0 448 512" onClick={() => setShowMobileMenu(!showMobileMenu)}><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
            </div>
            {showMobileMenu && 
                <div className={styles.backdrop} onClick={() => setShowMobileMenu(false)}>
                    <div className={styles.mobileMenu} onClick={(e) => e.stopPropagation()}>
                        <a href="https://order.chownow.com/order/38254/locations" target="_blank" className={styles.mobileMenuButton}>ORDER ONLINE</a>
                        <Link className={styles.mobileMenuText} to={"/"} onClick={() => handleButtonClick("Home")}>HOME</Link>
                        <Link className={styles.mobileMenuText} to={"/menus"} onClick={() => handleButtonClick("Menus")}>MENUS</Link>
                        <Link className={styles.mobileMenuText} to={"/gallery"} onClick={() => handleButtonClick("Gallery")}>GALLERY</Link>
                    </div>
                </div>
            }
        </header>
    )
}

export default Header;