import { Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from './Pages/Landing';
import Menus from './Pages/Menus';
import Menu from './Pages/Menu';
import Gallery from './Pages/Gallery';

function App() {
    return (
        <main className="app">
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/menus" element={<Menus />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="*" element={<Landing />} />
            </Routes>
        </main>
    );
}

export default App;
