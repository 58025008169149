import styles from './InlineCta.module.css';

function InlineCta() {
    const handleOrderClick = () => {
        if (localStorage.getItem('cookiePreferenceLaRumba') == "Off") return;
        
        gtag('event', 'purchase', {
            'event_category': 'Inline CTA',
            'event_action': 'Order Online',
            'value': 44.99,
            'currency': 'USD'
        });
    }

    return (
        <section className={styles.cta}>
            <p className={styles.title}>Enjoy a Classic To-Go!</p>
            <p className={styles.subtitle}>Savor our traditions, wherever you are.</p>
            <a href="https://order.chownow.com/order/38254/locations" target="_blank" className={styles.button} onClick={() => handleOrderClick()}>ORDER ONLINE</a>
        </section>
    )
}

export default InlineCta;