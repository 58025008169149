import { Helmet } from 'react-helmet';
import styles from './Menus.module.css';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import empp from '../Assets/empp.jpg';
import jarocho from '../Assets/jarocho.jpg';
import salad from '../Assets/salad.jpg';
import lunch from '../Assets/larumbalunch.pdf';
import dinner from '../Assets/larumbadinner.pdf';
import vegan from '../Assets/veganveg.pdf';

function Menus() {
    const handleButtonClick = (menuType: string) => {
        if (localStorage.getItem('cookiePreferenceLaRumba') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Menus Page',
            'event_action': menuType,
        });

        let pdfUrl = '';
        switch (menuType) {
            case 'Dinner':
                pdfUrl = dinner;
                break;
            case 'Lunch':
                pdfUrl = lunch;
                break;
            case 'Vegan':
                pdfUrl = vegan;
                break;
            default:
                break;
        }
    
        if (pdfUrl) {
            window.open(pdfUrl, '_blank');
        }
    }

    return (
        <section className={styles.menus}>
            <Helmet>
                <title>La Rumba | Menus</title>
                <meta name="description" content="Explore a culinary journey through Latin America at La Rumba in Asheville. Our diverse menus cover everything from traditional dishes to vegan delights, complemented by an extensive selection of bar drinks and cocktails." />
                <link rel="canonical" href="https://larumbaavl.com/menus" />
            </Helmet>
            <Header />
            <div className={styles.body}>
                <h1 className={styles.title}>La Rumba's Menus</h1>
                <div className={styles.line} />
                <div className={styles.grid}>
                    <div className={styles.gridBlock1} onClick={() => handleButtonClick("Dinner")}>
                        <div className={styles.gridImageContainer}>
                            <img src={empp} className={styles.gridImage} alt="Empanadas"/>
                            <div className={styles.overlay} />
                        </div>
                        <p className={styles.gridBlockTitle}>DINNER</p>
                        <h2 className={styles.gridBlockSubtitle}>The authentic flavors of Latin America.</h2>
                    </div>
                    {/* <div className={styles.gridBlock} onClick={() => handleButtonClick("Breakfast")}>
                        <div className={styles.gridImageContainer}>
                            <img src={chi} className={styles.gridImage} alt="Chilaquiles"/>
                            <div className={styles.overlay} />
                        </div>
                        <p className={styles.gridBlockTitle}>BREAKFAST</p>
                        <h2 className={styles.gridBlockSubtitle}>Awaken your palate with our delightful breakfast options.</h2>
                    </div> */}
                    <div className={styles.gridBlock} onClick={() => handleButtonClick("Lunch")}>
                        <div className={styles.gridImageContainer}>
                            <img src={jarocho} className={styles.gridImage} alt="Pollo Jarocho"/>
                            <div className={styles.overlay} />
                        </div>
                        <p className={styles.gridBlockTitle}>LUNCH</p>
                        <h2 className={styles.gridBlockSubtitle}>Delicious classics for lunch time.</h2>
                    </div>
                    <div className={styles.gridBlock} onClick={() => handleButtonClick("Vegan")}>
                        <div className={styles.gridImageContainer}>
                            <img src={salad} className={styles.gridImage} alt="King Salad Vegan"/>
                            <div className={styles.overlay} />
                        </div>
                        <p className={styles.gridBlockTitle}>VEGAN + VEGETARIAN</p>
                        <h2 className={styles.gridBlockSubtitle}>Purely vegan & vegetarian delights.</h2>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default Menus;