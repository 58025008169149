import { useEffect, useState } from 'react';
import styles from './Footer.module.css';
import PrivacyPolicy from './PrivacyPolicy';
import Cookies from './Cookies';

function Footer() {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showCookies, setShowCookies] = useState(false);

    useEffect(() => {
        if (showPrivacyPolicy || showCookies) document.body.classList.add('preventBgScroll');
        else document.body.classList.remove('preventBgScroll');
    }, [showPrivacyPolicy, showCookies]);

    const date = new Date();
    const year = date.getFullYear();

    const handleButtonClick = (action: string) => {
        if (localStorage.getItem('cookiePreferenceLaRumba') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Footer',
            'event_action': action,
        });
    }

    return (
        <footer className={styles.footer}>
            {showPrivacyPolicy && <PrivacyPolicy close={() => setShowPrivacyPolicy(false)}/>}
            {showCookies && <Cookies close={() => setShowCookies(false)}/>}
            <div className={styles.footerLeft}>
            <a href="https://archetypeavl.com"
                className={styles.footerText}
                style={{cursor: "pointer"}} 
                aria-label="Website by Archetype"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleButtonClick("Archetype")}
                >
                Website by Archetype
            </a>
            </div>
            <div className={styles.footerMid}>
                <p className={styles.footerText}>{`©${year} La Rumba Restaurant`}</p>
            </div>
            <div className={styles.footerRight}>
                    <p className={styles.footerRightText} onClick={() => {
                        handleButtonClick("Privacy Policy")
                        setShowPrivacyPolicy(true)
                    }}>Privacy Policy</p>
                    <p className={styles.footerRightText} onClick={() => {
                        handleButtonClick("Cookies")
                        setShowCookies(true)
                    }}>Manage Cookies</p>
            </div>
        </footer>
    )
}

export default Footer;