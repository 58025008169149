import styles from './Hero.module.css'
import hero from '../Assets/heroprod.mp4';

function Hero() {
    const handleOrderClick = () => {
        if (localStorage.getItem('cookiePreferenceLaRumba') == "Off") return;
        
        gtag('event', 'purchase', {
            'event_category': 'Hero',
            'event_action': 'Order Online',
            'value': 44.99,
            'currency': 'USD'
        });
    }

    return (
        <section className={styles.hero}>
            <div className={styles.heroLeft}>
                <h1 className={styles.title}>LATIN AMERICAN RESTAURANT IN ASHEVILLE, NC</h1>
                <p className={styles.subtitle}>LA RUMBA</p>
                <div className={styles.hours}>
                    <p className={styles.option}>MONDAY - SATURDAY | 11am - 9:30pm</p>
                    <p className={styles.option}>SUNDAY | 11am - 8pm</p>
                </div>
                <a href="https://order.chownow.com/order/38254/locations" target="_blank" className={styles.cta} onClick={() => handleOrderClick()}>ORDER ONLINE | ChowNow</a>
                <a href="https://snap.town/OrderLaRumba" target="_blank" className={styles.cta} onClick={() => handleOrderClick()} style={{backgroundColor: "transparent", color: "white"}}>ORDER ONLINE | Snaptown</a>
            </div>
            <div className={styles.heroBackgroundContainer}>
                <video className={styles.heroBackground} autoPlay loop muted playsInline>
                    <source src={hero} type="video/mp4" />
                </video>
            </div>
        </section>
    )
}

export default Hero;