import styles from './Located.module.css';
import avl from '../Assets/front.jpg';
import { Link } from 'react-router-dom';

function Located() {
    const handleButtonClick = () => {
        if (localStorage.getItem('cookiePreferenceLaRumba') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Located',
            'event_action': "View Gallery",
        });
    }

    return (
        <section className={styles.located}>
            <div className={styles.left}>
                <div className={styles.imageContainer}>
                    <img loading="lazy" src={avl} className={styles.image} alt="Asheville Scenery"/>
                </div>
            </div>
            <div className={styles.right}>
                <p className={styles.subtitle}>LOCATION</p>
                <p className={styles.title}>Nestled in Asheville</p>
                <p className={styles.desc}>Our menu represents the best of what our local markets have to offer. Our passion for sourcing fresh ingredients from farmers and suppliers we know, combining with traditional flavors and rewriting familiar recipes into exceptional cuisine is our mission and greatest joy.</p> 
                <Link className={styles.button} to={"/gallery"} onClick={() => handleButtonClick()}>VIEW GALLERY</Link>
            </div>
        </section>
    )
}

export default Located;