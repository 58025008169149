import styles from './About.module.css';

function About() {
    return (
        <section className={styles.about}>
            <p className={styles.text}>La Rumba® is Asheville's top restaurant for authentic Latin American cuisine, nestled in the heart of the city's vibrant culinary scene. Enjoyable for breakfast, lunch, and dinner. Immerse in Latin vibes perfect for all occasions. Our farm-fresh dishes blend tradition with innovation. From time-honored traditional hispanic dishes to the best vegan Latin American options in Asheville, La Rumba delivers an unmatched dining experience.</p>
        </section>
    )
}

export default About;